
























































































import { Button, Dialog, Field, Icon, Popup, Toast } from "vant";
import Vue from "vue";
import Component from "vue-class-component";
@Component({
  components: {
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Button.name]: Button
  }
})
export default class BatteryPlanOrderTeamManage extends Vue {
  planOrderId = 0;
  orderList = [];
  batteryIdList = [] as number[];
  ebikeQrCodeList = [] as string[];

  addEbikeDialog = { show: false, qrCode: "" };
  addBatteryDialog = { show: false, batteryId: "" as number | string };
  showEbikePopup = false;
  showBatteryPopup = false;

  created() {
    this.planOrderId = Number(this.$route.params.id || 0);
    this.getBatteryPlanTeamOrder();
    this.$readyWx(this.$axios, ["scanQRCode"]);
  }
  getBatteryPlanTeamOrder() {
    this.$axios.post("/api/manage/getBatteryPlanTeamDeviceList", { planOrderId: this.planOrderId }).then(e => {
      const data = e.data.data;
      this.orderList = data.orderList;
      this.ebikeQrCodeList = data.ebikeQrCodeList || [];
      this.batteryIdList = data.batteryIdList || [];
    });
  }
  toOrder(planOrderId: number) {
    this.$toUrl("/web/manager/battery_plan_order_detail?exchange_order_id=" + planOrderId);
  }
  addEbike(qrCode: string) {
    this.$axios.post("/api/manage/addBatteryPlanOrderTeamEbike", { planOrderId: this.planOrderId, qrCode: qrCode }).then(() => {
      this.getBatteryPlanTeamOrder();
      Toast.success("操作成功");
    });
  }
  removeEbike(qrCode: string) {
    Dialog.confirm({
      message: "确定要移除" + qrCode + "吗?"
    }).then(() => {
      this.$axios.post("/api/manage/removeBatteryPlanOrderTeamEbike", { planOrderId: this.planOrderId, qrCode: qrCode }).then(() => {
        this.getBatteryPlanTeamOrder();
        Toast.success("操作成功");
      });
    });
  }
  addBattery(batteryId: string) {
    this.$axios.post("/api/manage/addBatteryPlanOrderTeamBattery", { planOrderId: this.planOrderId, batteryId: batteryId }).then(() => {
      this.getBatteryPlanTeamOrder();
      Toast.success("操作成功");
    });
  }
  removeBattery(batteryId: string) {
    Dialog.confirm({
      message: "确定要移除" + batteryId + "吗?"
    }).then(() => {
      this.$axios.post("/api/manage/removeBatteryPlanOrderTeamBattery", { planOrderId: this.planOrderId, batteryId: batteryId }).then(() => {
        this.getBatteryPlanTeamOrder();
        Toast.success("操作成功");
      });
    });
  }
  scanEbikeQrCode() {
    this.$scanCode(content => {
      this.addEbikeDialog.qrCode = content;
    });
  }
  scanBatteryId() {
    this.$scanCode(content => {
      this.addBatteryDialog.batteryId = content;
    });
  }
}
